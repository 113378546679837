import {Auth} from 'aws-amplify'

export const createAccount = async ({phone, email, password}) => {
  try {
    await Auth.signUp({
      username: phone,
      password,
      attributes: {
        email,
        phone_number: phone
      }
    })
  } catch (error) {
    console.log('error creating account:', error)
    throw error
  }
}

export const confirmAccount = async ({phone, code}) => {
  try {
    await Auth.confirmSignUp(phone, code)
  } catch (error) {
    console.log('error confirming account:', error)
    throw error
  }
}

export const resendAccountConfirmationCode = async ({phone}) => {
  try {
    await Auth.resendSignUp(phone)
  } catch (error) {
    console.log('error resending account confirmation code:', error)
    throw error
  }
}

export const logIn = async ({phone, password}) => {
  try {
    const user = await Auth.signIn({username: phone, password})
    return user
  } catch (error) {
    console.log('error logging in:', error)
    throw error
  }
}

export const logOut = async () => {
  try {
    await Auth.signOut()
  } catch (error) {
    console.log('error logging out:', error)
    throw error
  }
}

export const sendPasswordResetCode = async ({phone}) => {
  try {
    await Auth.forgotPassword(phone)
  } catch (error) {
    console.log('error sending password reset code:', error)
    throw error
  }
}

export const resetPassword = async ({phone, code, password}) => {
  try {
    await Auth.forgotPasswordSubmit(phone, code, password)
  } catch (error) {
    console.log('error resetting password: ', error)
    throw error
  }
}

export const confirmLogin = async ({user, code}) => {
  try {
    const confirmedUser = await Auth.confirmSignIn(user, code)
    return confirmedUser
  } catch (error) {
    console.log('error confirming login:', error)
    throw error
  }
}

export const getCurrentUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user
  } catch (error) {
    console.log('error getting current user: ', error)
    return null
  }
}

export const getCurrentUserInfo = async (user) => {
  try {
    const userInfo = await Auth.currentUserInfo(user)
    return userInfo
  } catch (error) {
    console.log('error getting user info:', error)
    throw error
  }
}

export const getUserAttributes = async (user) => {
  try {
    const userAttributes = await Auth.userAttributes(user)
    return userAttributes
  } catch (error) {
    console.log('error getting user attributes:', error)
    throw error
  }
}

export const isPhoneVerified = async () => {
  try {
    const currentUser = await getCurrentUser()
    const userAttributes = await getUserAttributes(currentUser)
    const phoneVerifiedAttribute = userAttributes.find(a => a.Name === 'phone_number_verified') || {}
    return phoneVerifiedAttribute.Value === 'true'
  } catch (error) {
    console.log('error checking email verification: ', error)
    throw error
  }
}

export const isEmailVerified = async () => {
  try {
    const currentUser = await getCurrentUser()
    return isEmailVerifiedForUser(currentUser)
  } catch (error) {
    console.log('error checking email verification: ', error)
    throw error
  }
}

export const isEmailVerifiedForUser = async (user) => {
  try {
    if(!user) {
      return false
    }
    const userAttributes = await getUserAttributes(user)
    const emailVerifiedAttribute = userAttributes.find(a => a.Name === 'email_verified') || {}
    return emailVerifiedAttribute.Value === 'true'
  } catch (error) {
    console.log('error checking email verification: ', error)
    throw error
  }
}

export const sendEmailConfirmationCode = async () => {
  try {
    const currentUser = await getCurrentUser()
    await Auth.verifyUserAttribute(currentUser, 'email')
  } catch (error) {
    console.log('error sending email confirmation code: ', error)
    throw error
  }
}

export const confirmEmail = async ({code}) => {
  try {
    await Auth.verifyCurrentUserAttributeSubmit('email', code)
  } catch (error) {
    console.log('error confirming email: ', error)
    throw error
  }
}

