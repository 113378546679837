// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-confirm-email-js": () => import("./../../../src/pages/auth/confirm-email.js" /* webpackChunkName: "component---src-pages-auth-confirm-email-js" */),
  "component---src-pages-auth-confirm-phone-js": () => import("./../../../src/pages/auth/confirm-phone.js" /* webpackChunkName: "component---src-pages-auth-confirm-phone-js" */),
  "component---src-pages-auth-create-account-js": () => import("./../../../src/pages/auth/create-account.js" /* webpackChunkName: "component---src-pages-auth-create-account-js" */),
  "component---src-pages-auth-forgot-password-js": () => import("./../../../src/pages/auth/forgot-password.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-auth-reset-password-js": () => import("./../../../src/pages/auth/reset-password.js" /* webpackChunkName: "component---src-pages-auth-reset-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

