import './src/styles/index.css'
import React from 'react'
import {AuthProvider} from './src/contexts'
import Amplify from 'aws-amplify'
import awsconfig from './src/aws-exports'

export const onClientEntry = () => {
  Amplify.configure(awsconfig)
}

export const wrapRootElement = ({element}) => (
  <AuthProvider>
    {element}
  </AuthProvider>
)

