import React, {useEffect, useRef} from 'react'

const useDefensiveEffect = (effect, dependencies = []) => {
  const status = {} // mutable status object

  const isMountedRef = useRef(null)

  useEffect(() => {
    status.aborted = false
    isMountedRef.current = true
    // pass the mutable object to the effect callback
    // store the returned value for cleanup
    const cleanUpFn = effect({status, isMountedRef})
    return () => {
      // mutate the object to signal the consumer
      // this effect is cleaning up
      status.aborted = true
      isMountedRef.current = false
      if (typeof cleanUpFn === "function") {
        // run the cleanup function
        cleanUpFn()
      }
    }
  }, [...dependencies])
}

export {useDefensiveEffect}

export default useDefensiveEffect