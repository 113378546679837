import React from 'react'
import {AuthContext} from '../contexts'

const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('`useUser` must be within a `AuthProvider`')
  }
  return context || {}
}

export {useAuth}

export default useAuth