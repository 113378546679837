import {useState} from 'react'
import useIsMountedRef from './use-mounted-ref'

const useSafeState = (initialState) => {
  const isMountedRef = useIsMountedRef()
  let [state, setState] = useState(initialState)
  return [state, (state) => {
    if (isMountedRef.current) {
      setState(state)
    }
  }]
}

export {useSafeState}

export default useSafeState