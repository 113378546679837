/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'

const useScript = ({src, text, crossorigin}) => {
  useEffect(() => {
    const script = document.createElement('script')
    if (src) {
      script.src = src
    }
    if (text) {
      script.text = text
    }
    if (crossorigin) {
      script.crossorigin = crossorigin
    }
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [src, text])
}

export {useScript}

export default useScript