import React from 'react'
import {useDefensiveEffect, useSafeState} from '../hooks'
import {getCurrentUser, isEmailVerifiedForUser} from '../backend'

const AuthContext = React.createContext(null)

const AuthProvider = ({children}) => {
  const [loading, setLoading] = useSafeState(true)
  const [user, setUser] = useSafeState(undefined)
  const [credentials, setCredentials] = useSafeState(undefined)
  const [emailVerified, setEmailVerified] = useSafeState(false)

  const refreshAuthContext = async () => {
    setLoading(true)
    return getCurrentUser()
      .then((userResult) => {
        setUser(userResult)
        return userResult
      })
      .then(isEmailVerifiedForUser)
      .then((emailVerified) => {
        setEmailVerified(emailVerified)
        setLoading(false)
      })
      .catch(() => {
        setUser(null)
        setEmailVerified(false)
        setLoading(false)
      })
  }

  // fetch the info of the user that may be already logged in
  useDefensiveEffect(() => {
    refreshAuthContext()
  }, [])

  // make sure other components can read this value
  return (
    <AuthContext.Provider value={{
      loading,
      user,
      authenticated: !!user && emailVerified,
      setUser,
      refreshAuthContext,
      credentials,
      setCredentials,
      emailVerified,
      setEmailVerified
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export {AuthProvider, AuthContext}

export default AuthProvider